import { graphql, useStaticQuery } from "gatsby"

const useFirstBlogPost = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: {order: ASC, fields: contentful_id}) {
            edges {
              node {
                pageTitle
                slug
                createdDate
                cardBlogListing {
                  id
                  titleCard
                  imageCard {
                    fluid(maxWidth: 800){
                      ...GatsbyContentfulFluid_withWebp
                    }
                    id
                  }
                  descriptionCard {
                    descriptionCard
                    childMarkdownRemark {
                      id
                    }
                  }
                }
                categories
              }
            }
          }
      }
    `
  )
  return allContentfulBlogPost?.edges[0]?.node
}
export default useFirstBlogPost