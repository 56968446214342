/* eslint-disable */
import React from 'react'
import Layout from '../components/Layout'
import Gettouch from '../components/Gettouch'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby';
import CardListing from '../components/BlogLising/CardsListing'
import HeroBlog from '../components/BlogLising/HeroBlog'
import useFeaturedBlogPost from "../hooks/useFeaturedPost"


import ReadDetails from '../components/BlogPost/ReadDetails'
import GetIntouchRead from '../components/BlogPost/GetIntouchRead'

const BlogPostPage = props => {
  const postBody = props.data.allContentfulBlogPost.edges[0].node
  const title = props.data.allContentfulBlogPost.edges[0].node.pageTitle
  
  return (
    <Layout>
      <Helmet>
        <title>Urbian | {title}</title>
      </Helmet>
      <ReadDetails 
        data={postBody} 
        currentCat={props.pageContext.currentCat} 
        location={props.location}
        
      />
      <GetIntouchRead
        location={props.location.pathname} 
        next={props.pageContext.next}
      />
    </Layout>
  )
}


export default BlogPostPage


export const contentfulBlogPostPageQuery = graphql`
  query blogPostPageWpQuery($id: String!) {
    allContentfulBlogPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          tags
          createdDate(formatString: "MMMM DD, YYYY")
          createdAt(formatString: "MMMM DD, YYYY")
          pageTitle
          cardBlogListing {
            id
            titleCard
            imageCard {
              fluid(maxWidth: 800){
                ...GatsbyContentfulFluid_withWebp
              }
              id
            }
            descriptionCard {
              descriptionCard
              childMarkdownRemark {
                id
              }
            }
          }
          authorInformation {
            nameAuthor
            biography {
              childMarkdownRemark {
                html
              }
            }
            positionAuthor
            iconImageAuthor {
              id
              fluid(maxWidth: 300){
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          categories
          postBody {
            mainarticlePostImage {
              fluid(maxWidth: 600){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            childContentfulPostBodyPostBodyTextTextNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

