/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'gatsby'
import useFirstPost from "../../../hooks/useFirstPost"

const FirstPost = (props) => {
    const firstPost = useFirstPost()
    return (
      <div>
       
            <Link to={`/read/${firstPost.slug}`}>
                <span>Next up</span>
                <h2>
                    {firstPost.cardBlogListing[0].titleCard}
                </h2>
                <p><div dangerouslySetInnerHTML={{ __html: `<div> ${firstPost.cardBlogListing[0].descriptionCard.descriptionCard} </div>` }} /> </p>
            </Link>
        
      </div>
    )
  }


export default FirstPost