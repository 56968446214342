/* eslint-disable */
import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Form from 'react-bootstrap/Form'
import './styled.css'
import axios from "axios";
import { navigate } from 'gatsby'
import FirstPost from './firstPost'

class GetIntouchRead extends React.Component {
    
    constructor () {
      super();
      this.state = {
        email: null,
        message:''
      };
      
      this.__handleChange = this._handleChange.bind(this);
      this._handleSubmit = this._handleSubmit.bind(this)
    }

    _handleChange = e => {
      e.preventDefault();
      
      this.setState({
        [`${e.target.name}`]: e.target.value,
      })
    }

    _handleSubmit = e => {
      e.preventDefault();
      addToMailchimp(this.state.email)
      .then(({msg, result}) => {
        if (result !== 'success') {
          throw msg;          
        }
        navigate('/subscription-thank-you');
      })
      .catch(err => {
        this.setState(function(state, props) {
          return {
            message: err
          }
        });
      });
    }
    render() {
      
      return (
      <div className="readdsetails">
        <div className="nextup">
          <Container>
            <Row className="align-items-center">
              <Col md="6" lg="6">
                { this.props.next && (
                  <Link to={`/read/${this.props.next.slug}`}>
                    <span>Next up</span>
                    <h2>
                      {this.props.next.cardBlogListing[0].titleCard}
                    </h2>
                    <p><div dangerouslySetInnerHTML={{ __html: `<div> ${this.props.next.cardBlogListing[0].descriptionCard.childMarkdownRemark.html} </div>` }} /> </p>
                  </Link>
                )} 
                { !this.props.next && (
                  <FirstPost />
                )}
              </Col>
              <Col className="newsletterbox offset-lg-1" md="6" lg="5">
                <div className="form-div">
                  <p>Stay in the loop with our quartely newsletter </p>
                  <Form className="mt-5" name="subscribe" onSubmit={this._handleSubmit}>
                    <input type="hidden" name="email" value="subscribe" />
                    <Form.Group controlId="">
                      <Form.Control
                        type="email"
                        placeholder="Your email"
                        name="email"
                        onChange={this._handleChange}
                        required
                      />
                    </Form.Group>
                    <button type="submit" class="styled__Button-ksqKNN iyLmoo styled__Button-kGpwPX dQtCFI form-contact-button">
                      Send
                      <svg
                        className="Arrow__Svg-gllSXh SARKW styled__Arrow-ihTfeJ cFLifL"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#ffffff" fill="none" fillRule="evenodd">
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                        </g>
                      </svg>
                    </button>
                  </Form>
                  <p className="form-error-message"><div dangerouslySetInnerHTML={{ __html: `<div> ${this.state.message} </div>` }} /> </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default GetIntouchRead